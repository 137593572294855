import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import emailMobile from "../assets/imgs/mobile/email.png";
function Footer(props) {
  if (props.isMobile) {
    return (
      <footer id="footer" className="footer-mobile">
        <div className="one-center">
          <div className="title">Regulations</div>
          <Router>
            <div className="infos">
              <Link to="/privacyDoc" target="_blank">
                《Privacy agreement》
              </Link>
            </div>
            <div className="infos">
              <Link to="/serviceDoc" target="_blank">
                《service agreement》
              </Link>
            </div>
          </Router>
        </div>
        <div className="one-center-end">
          <div className="title">OPeso</div>
          <div className="infos">
            <img src={emailMobile} className="contImg" alt="fottOne" />
            <div>service@opeso.ph</div>
          </div>
          {/* <div>
              <img src={phones} className="contImg" alt='phones'/>
              <p>+51997938924</p>
            </div>
            <div>
              <img src={adress} className="contImg" alt='phones'/>
              <p>AV. REPUBLICA DE PANAMA NRO. 3535 INT. 403 - LIMA LIMA SANISIDRO</p>
            </div> */}
          {/* <div>
              <svg>
                <use xlinkHref="#iconaddress" />
              </svg>
              <p>2206, 88 Corporate Center, Sedeño,makati Metro Manila, Philippines</p>
            </div> */}
        </div>
      </footer>
    );
  }
  return (
    <footer id="footer" className={"footer"}>
      <div className="bar">
        <div className="one-center">
          <div className="title"></div>
          <div className="infos">
            <svg className="contImg">
              <use xlinkHref="#iconicon" />
            </svg>
            <div>service@opeso.ph</div>
          </div>
        </div>
        <div className="one-center one-center-end">
          <div className="title">Regulations</div>
          <Router>
            <div className="infos">
              <Link to="/privacyDoc" target="_blank">
                《Privacy agreement》
              </Link>
            </div>
            <div className="infos">
              <Link to="/serviceDoc" target="_blank">
                《service agreement》
              </Link>
            </div>
          </Router>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2024 | OPeso | All Rights Reserved
      </div>
    </footer>
  );
}

export default Footer;
