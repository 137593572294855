import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import { enquireScreen } from "enquire-js";
// import check from "../../assets/imgs/check.png";
import phLogo from "../../assets/imgs/apple.png";
import moreImg from "../../assets/imgs/more.png";

import "./index.less";
import "./index-Pc.less";

// const lenLimit = "Número de @len dígitos";
// const rangeLimit = "Número de @min-@max dígitos";
// const phoneLimit = "Número de @num dígitos, que comienzan con @num";

const limits = {
  ph: {
    lenLimit: "Número de @len dígitos",
    rangeLimit: "Número de @min-@max dígitos",
    phonePattern: /^((9[0-9])|(09[0-9])|(8[0-9])|(08[0-9]))\d{8}$/,
    phoneLen: 10,
    phoneLimit: "Please input 10-digit Philippine number starting with 9 or 8",
  },
};

export class DeleteUser extends React.PureComponent {
  state = {
    hasSubmit: false,
    textInfo: null,
    textFocus: false,
    inputFocus: false,
    textPhone: null,
    message: null,
    hasCommit: false,
    idCardType: null,
    idCardTypeDesc: null,
    idCard: null,
    idCardError: null,
    idCardRule: null,
    idCardRuleMsg: null,
    idCardFocus: false,
    agreeCheck: true,
    isMobile: false,
    showError: false,
    showModal: false,
    showDict: null,
    errorMsg: null,
    localCountryKey: "ph",
    localCountry: 'ph',

    localCountrys: [
      { logo: phLogo, label: "Philippine", value: "ph" },
    ],
    idCardTypeDict: [
      {
        value: 1,
        label: "UMID(Unified Multi-Purpose ID)",
        rule: /^[0-9]{10,12}$/,
        ruleMsg: "10-12 Digit Numbers",
        prev: "UMID",
      },
      {
        value: 2,
        label: "TIN(Taxpayer Identification Number ID)",
        rule: /^(([0-9]{9})|([0-9]{12})|([0-9]{14})){1}$/,
        ruleMsg: "9,12,14-Digit Numbers",
        prev: "TIN",
      },
      {
        value: 3,
        label: "Driver’s License",
        rule: /^[A-Za-z][0-9]{10}$/,
        prev: "Driver’s License",
        ruleMsg: "Consist of 1 letter + 10 numbers",
      },
      {
        value: 4,
        label: "SSS(Social Security System ID)",
        rule: /^[0-9]{10}$/,
        prev: "SSS",
        ruleMsg: "10-Digit Numbers",
      },
      {
        value: 5,
        label: "Passport",
        rule: /^(?=(?:\D*\d){7})(?=(?:\d*\D){2})[A-Za-z0-9]{9}$/,
        prev: "Passport",
        ruleMsg: "Consist of 2-digit letters + 7-digit numbers",
      },
      {
        value: 6,
        label: "Postal ID",
        rule: /^[A-Za-z](?=(?:\D*\d){11})[A-Za-z0-9]{11}$/,
        prev: "Postal ID",
        ruleMsg: "Consist of 1-digit letter + 11-digit numbers",
      },

      {
        value: 7,
        label: "PRC ID(Professional Regulation Commission)",
        rule: /^\d{7}$/,
        prev: "PRC ID",
        ruleMsg: "Consist of 7-digit numbers",
      },
      {
        value: 8,
        label: "GSIS ID(Government Service Insurance System)",
        prev: "GSIS ID",
        rule: /^[0-9]+$/,
        ruleMsg: "Numbers",
      },
      {
        value: 9,
        label: "Voter's ID(COMELEC)",
        rule: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{22,23}$/,
        prev: `Voter's ID(COMELEC)`,
        ruleMsg: "Consist of 22 or 23-digit letters + numbers",
      },
      {
        value: 10,
        label: "PIC(PhilHealth Indentification Card)",
        rule: /^[0-9]{12}$/,
        prev: "PIC",
        ruleMsg: "12-Digit Numbers",
      },
      {
        value: 11,
        label: "NBI(National Bureau of Investigation Clearance)",
        rule: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{20}$/,
        prev: "NBI",
        ruleMsg: "20-Digit Letters + Numbers",
      },
      {
        value: 12,
        label: "National ID",
        rule: /^[0-9]{16}$/,
        prev: "National ID",
        ruleMsg: "16-Digit Numbers",
      },
    ],
  };
  componentDidMount() {
    const stateTempJSON = localStorage.getItem("stateTemp");
    if (stateTempJSON) {
      this.setState({ ...JSON.parse(stateTempJSON) });
      setTimeout(() => {
        localStorage.removeItem("stateTemp");
      }, 500);
    }
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }

  handleFocusText = (flag) => {
    this.setState({ textFocus: flag });
  };
  buscarChangeText = (e) => {
    const { value } = e.target;
    this.setState({ textInfo: value });
  };
  buscarChangePhone = (e) => {
    const { value } = e.target;
    const textPhone = value.replace(/[^0-9-]/g, "");
    // this.props.form.setFieldsValue({phone: textPhone})
    const { phoneLimit, phonePattern, phoneLen } =
      limits['ph'];
    let errorMsg = null;
    errorMsg = !phonePattern.test(textPhone)
      ? phoneLimit.replace(/@num/g, phoneLen)
      : null;

    this.setState({ textPhone, message: errorMsg });
  };
  buscarChangeIdCard = (event) => {
    let inputValue = event.target.value;
    // const { idCardPattern, idCardLimit, lenLimit, rangeLimit } =
    const { idCardPattern, idCardLimit } = limits['ph'];
    let value = null;

    // const min = 7;
    const max = 9;
    let errorMsg = idCardLimit;
    if (inputValue) {
      let length = inputValue.length;
      value = inputValue;

      if (length <= max) {
        if (!idCardPattern.test(String(inputValue))) {
          let checkValue = String(inputValue).toUpperCase();
          const endWord = "K";
          //截断endWord后面的字符
          if (endWord && checkValue.indexOf(`${endWord}`) > -1) {
            const checkValues = checkValue.split(`${endWord}`);
            value =
              checkValues[0].replace(/[^0-9.]/g, "").replace(/[-+e]/g, "") +
              endWord; //追加endWord
          } else {
            value = checkValue.replace(/[^0-9.]/g, "").replace(/[-+e]/g, "");
          }
        } else {
          errorMsg = null;
        }
      } else {
        value = inputValue.replace(/[^0-9.]/g, "").replace(/[-+e]/g, "");
      }
    } else {
      errorMsg = null;
    }

    const idCard = value;
    // this.props.form.setFieldsValue({idCard})

    // let vLen = String(idCard || "").trim().length;
    // if (vLen > 0) {
    //   if (min && max) {
    //     if (min > vLen || max < vLen) {
    //       errorMsg = rangeLimit
    //         .replace(/@max/g, max + "")
    //         .replace(/@min/g, min + "");
    //     }
    //   } else {
    //     if (min && min > vLen) {
    //       errorMsg = lenLimit + " >= " + min;
    //     }
    //     if (max && max < vLen) {
    //       errorMsg = lenLimit + " <= " + max;
    //     }
    //   }
    // }
    this.setState({ idCardError: errorMsg });
    this.setState({ idCard });
  };
  handleChangeIdCard = (event) => {
    let inputValue = event.target.value;
    const idCard = String(inputValue || "").trim();
    // this.props.form.setFieldsValue({idCard})
    const { idCardRule, idCardRuleMsg } = this.state;
    let errorMsg = idCardRuleMsg;
    let vLen = idCard.length;
    if (vLen > 0) {
      if (idCardRule.test(idCard)) {
        errorMsg = null;
      }
    }
    this.setState({ idCard, idCardError: errorMsg });
  };
  handleSumit = async () => {
    const {
      textPhone,
      textInfo,
      idCard,
      message,
      idCardError,
      agreeCheck,
      hasCommit,
      idCardType,
    } = this.state;
    if (hasCommit || message || idCardError || !agreeCheck) {
      return;
    }
    if (textPhone) {
      this.setState({ showError: true, errorMsg: null });
      let payload = {
        idCardType,
        idCard,
        reason: textInfo,
        phone: textPhone,
        appId: 2,
      };
      const res = await this.getData(payload);
      if (res && res.ret === 0) {
        this.setState({});
        this.setState({ hasCommit: false, showError: false, hasSubmit: true });
      } else if (res && res.ret !== 0) {
        this.setState({ errorMsg: res.msg, showError: true });
        // notification.error({
        //   message: res.msg,
        //   duration: 3,
        // })
      }
    }
  };
  handleOK = () => {
    this.setState({ showError: false, errorMsg: null });
  };
  checkStatus = (response) => {
    const codeMessage = {
      200: "200",
      201: "201",
      202: "202",
      204: "204",
      400: "400",
      401: "401",
      403: "403",
      404: "404",
      406: "406",
      410: "410",
      422: "422",
      500: "500",
      502: "502",
      503: "503",
      504: "504",
    };
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const errortext = codeMessage[response.status] || response.statusText;
    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
  };
  getData = (payload) => {
    const urlInfo = {
      ph: {
        url: "/elops",
        errorMsg: "Falla del Sistema",
      },
    };
    const urlObj = urlInfo[this.state.localCountryKey];
    const res = fetch(window.location.origin + urlObj.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify(payload),
    })
      .then(this.checkStatus)
      .then((res) => {
        return res.json();
      })
      .catch((e, res) => {
        const status = e.name;
        let ret = status;
        let msg = urlObj.errorMsg;
        return { ret, msg };
      });
    return res;
  };

  buscarAgreeCheck = () => {
    let agreeCheck = this.state.agreeCheck;
    agreeCheck = !agreeCheck;
    this.setState({
      agreeCheck,
    });
  };

  handleSelectChange = (event, item, showDict) => {
    event.preventDefault();
    const { value, label } = item;
    switch (showDict) {
      case "country":
        this.setState({
          showModal: false,
          localCountry: item,
          localCountryKey: value,
          idCardType: item.value === "ph" ? null : 1,
          idCardTypeDesc: "",
          idCardError: null,
          idCard: "",
          reason: "",
          textInfo: null,
          textFocus: false,
          inputFocus: false,
          textPhone: null,
          message: null,
        });
        break;
      case "idCardType":
        if (value === -1) {
          this.setState({
            showModal: false,
            idCardType: null,
            idCardTypeDesc: "",
            idCardError: null,
            idCard: "",
          });
          return;
        }
        const { idCardType } = this.state; // 上次的值

        // ! idCardType 的值和上次的选择不一致,才清空 idCard
        if (idCardType !== value) {
          this.setState({
            idCardError: false,
            idCard: "",
          });
        }
        this.setState({
          showModal: false,
          idCardType: value,
          idCardTypeDesc: label,
          idCardRule: item.rule,
          idCardRuleMsg: item.ruleMsg,
          idCardError: item.ruleMsg,
        });
        break;
      default:
        break;
    }
  };
  render() {
    const {
      hasSubmit,
      showError,
      showModal,
      showDict,
      idCardTypeDict,
      errorMsg,
      agreeCheck,
      isMobile,
      idCardError,
      textPhone,
      inputFocus,
      idCardFocus,
      textFocus,
      message,
      idCard,
      idCardType,
      idCardTypeDesc,
      localCountry,
    } = this.state;

    const deleteDesc = {
      ph: {
        agsTitle: "Request to delete account",
        agsContry: "Country/País",
        agsTop: null,
        agsAreaCode: "+63",
        agsPhoneTitle: "Phone(Account in OPeso)",
        agsPhone: "*********",
        asgCardType: "ID Type(Submit in OPeso)",
        asgCard: "ID Number(Submit in OPeso)",
        asgMsg: "Kindly provide the reason why you want to delete your account",
        asgMsgTip:
          "The number of words exceeds the maximum limit. The maximum number of words is 100.",
        asgAgree: "I accept and agree to",
        Privacidad: "《Privacy Agreement》",
        Servicio: "《Service Agreement》",
        agreeAnd: " and ",
        submit: "Submit",
        okText: "CONFIRMAR",
        loadingText: "Loading...",
        result:
          "Successfully submitted, we will contact you as soon as possible to verify the information. Please keep your phone open",
      },
    };
    const deleteKeys = deleteDesc['ph'];
    const renderDict = () => {
      let dicts = idCardTypeDict;
      const title = deleteKeys.asgCardType;
      return (
        <div
          className={["selectModal", isMobile && "selectModal-mob"].join(" ")}
          onClick={() => {
            this.setState({ showModal: false });
          }}
        >
          <div className={["modalContent"].join(" ")}>
            <div className={"listHead"}>
              <div>{title}</div>
            </div>
            <div className={["list"].join(" ")}>
              {dicts.map((i, index) => (
                <div
                  className={[
                    "listItem",
                    idCardType === i.value && "listItemOn",
                  ].join(" ")}
                  key={i.value}
                  checked={idCardType === i.value}
                  onClick={(e) => this.handleSelectChange(e, i, showDict)}
                >
                  {i.label}
                </div>
              ))}
              {showDict === "idCardType" && (
                <div
                  className={["listItem"].join(" ")}
                  key={-1}
                  onClick={() =>
                    this.handleSelectChange({ value: -1 }, showDict)
                  }
                >
                  {"cancel"}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    };
    return (
      <div
        className={[
          "deleteWrapper" + (isMobile ? "-mob" : ""),
          (idCardFocus || textFocus || inputFocus) &&
            isMobile &&
            "edit" + (isMobile ? "-mob" : ""),
          hasSubmit && "deleteWrapperColor" + (isMobile ? "-mob" : ""),
        ].join(" ")}
      >
        <div className={"tabNav"}>
          <div className={"title"}> {deleteKeys.agsTitle}</div>
        </div>
        {!hasSubmit ? (
          <>
            <div className={"content"}>
            <>
                  <div className={"fbtInfo fbtInfoEnd"}>
                    <div className={"fbtTitle"}>{deleteKeys.agsPhoneTitle}</div>
                    <div
                      className={[
                        "fbtItem",
                        "fbtPhone",
                        "fbtTop",
                        inputFocus && "fbtContentOn",
                        !!message && "fbtContentError",
                      ].join(" ")}
                    >
                      <div className={"fpInfo"}>
                        <div className={"fpLabel"}>
                          {deleteKeys.agsAreaCode}
                        </div>
                        <input
                          className={"fpInput"}
                          onChange={(value) => this.buscarChangePhone(value)}
                          onKeyUp={(event) => this.buscarChangePhone(event)}
                          placeholder={deleteKeys.agsPhone}
                          onFocus={() => {
                            this.setState({ inputFocus: true });
                          }}
                          onBlur={() => {
                            this.setState({ inputFocus: false });
                          }}
                          type={"tel"}
                          maxLength={10}
                        />
                      </div>
                    </div>
                    {!!message && (
                      <div className={["showTips"].join(" ")}>
                        <span>{message}</span>
                      </div>
                    )}

                    <div className={"fbtTitle"}>{deleteKeys.asgCardType}</div>

                    <div
                      className={["fbtItem", "fbtCountry"].join(" ")}
                      onClick={() => {
                        this.setState({
                          showModal: true,
                          showDict: "idCardType",
                        });
                      }}
                    >
                      <div className={"fpInfo"}>
                        <input
                          className={"iSelect"}
                          placeholder={""}
                          readOnly={true}
                          value={idCardTypeDesc}
                        />
                        <div className="countryMore">
                          <img
                            src={moreImg}
                            className="moreImg"
                            alt="moreImg"
                          />
                        </div>
                      </div>
                    </div>

                    {!!idCardTypeDesc && (
                      <>
                        <div className={"fbtTitle"}>{deleteKeys.asgCard}</div>

                        <div
                          className={[
                            "fbtItem",
                            "fbtPhone",
                            idCardFocus && "fbtContentOn",
                            !!idCardError && "fbtContentError",
                          ].join(" ")}
                        >
                          <div className={"fpInfo"}>
                            <input
                              className={[
                                "fbtItem",
                                "fpInput",
                                "fpIdCard",
                              ].join(" ")}
                              onKeyUp={(event) =>
                                this.handleChangeIdCard(event)
                              }
                              placeholder={""}
                              onFocus={() => {
                                this.setState({ idCardFocus: true });
                              }}
                              onBlur={() => {
                                this.setState({ idCardFocus: false });
                              }}
                              value={idCard}
                              onChange={(currentValue) => {
                                this.handleChangeIdCard(currentValue);
                              }}
                            />
                          </div>
                        </div>
                        {!!idCardError && (
                          <div className={["showTips"].join(" ")}>
                            <span>{idCardError}</span>
                          </div>
                        )}
                      </>
                    )}

                    <div className={"fbtTitle"}>{deleteKeys.asgMsg}</div>

                    <div
                      className={[
                        "fbtItem",
                        "fbtContent",
                        textFocus ? "fpTextOn" : null,
                      ].join(" ")}
                    >
                      <textarea
                        maxLength={100}
                        onChange={(value) => this.buscarChangeText(value)}
                        className={[
                          "fpText",
                          textFocus ? "fpTextOn" : null,
                        ].join(" ")}
                        onFocus={() => this.handleFocusText(true)}
                        onBlur={() => this.handleFocusText(false)}
                        placeholder={deleteKeys.asgMsgTip}
                      />
                    </div>
                  </div>
                </>
              {/* agree checkbox */}
              {!!localCountry && (
                <Router>
                  <div className={"agreeWrapper"}>
                    <div
                      className={["laCheck", agreeCheck && "hasCheck"].join(
                        " "
                      )}
                      onClick={this.buscarAgreeCheck}
                    >
                      {agreeCheck && (
                        // <img className="laImg" src={check} alt="s" />
                        <span>✔</span>
                      )}
                    </div>
                    <div className={"laText"}>
                      <span className={"normolColor"}>
                        {deleteKeys.asgAgree}
                      </span>
                      <Link
                        to={"/serviceDoc"}
                        target="_blank"
                      >
                        <span className={"doc"}>{deleteKeys.Servicio}</span>
                      </Link>
                      <span
                        className={"normolColor"}
                        style={{ margin: "0 3px" }}
                      >
                        {deleteKeys.agreeAnd}
                      </span>
                      <Link
                        to={"/privacyDoc"}
                        target="_blank"
                      >
                        <span className={"doc"}>{deleteKeys.Privacidad}</span>
                      </Link>
                    </div>
                  </div>
                </Router>
              )}
              {!isMobile && (
                <div
                  className={[
                    "fbtAction",
                    textPhone && !idCardError && agreeCheck && !message
                      ? "fbtActionOn"
                      : null,
                  ].join(" ")}
                  onClick={() => this.handleSumit()}
                >
                  <div className={"fbtDo"}>{deleteKeys.submit}</div>
                </div>
              )}
            </div>
            {isMobile && (
              <div
                className={[
                  "fbtAction",
                  textPhone && !idCardError && agreeCheck && !message
                    ? "fbtActionOn"
                    : null,
                ].join(" ")}
                onClick={() => this.handleSumit()}
              >
                <div className={"fbtDo"}>{deleteKeys.submit}</div>
              </div>
            )}
          </>
        ) : (
          <div className={"fbtSuccess"}>
            <span>{deleteKeys.result}</span>
          </div>
        )}
        {showModal && renderDict()}
        {showError && (
          <div className={["errorModal"].join(" ")}>
            <div
              className={["errorContent", !errorMsg && "loadingContent"].join(
                " "
              )}
            >
              {errorMsg ? (
                <div
                  className={[
                    "errorHeader",
                    isMobile && "errorHeader-mob",
                  ].join(" ")}
                ></div>
              ) : (
                <div
                  className={["loader", isMobile && "loader-mob"].join(" ")}
                />
              )}
              <div
                className={["errorMsg", isMobile && "errorMsg-mob"].join(" ")}
              >
                {errorMsg || deleteKeys.loadingText}
              </div>
              {errorMsg && (
                <div
                  onClick={() => this.handleOK()}
                  className={["btn", isMobile && "btn-mob"].join(" ")}
                >
                  {deleteKeys.okText}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DeleteUser;
